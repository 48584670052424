import AddPhoneNumber from '@/screens/home/PhoneVerification/AddPhoneNumber';
import VerifyPhoneNumber from '@/screens/home/PhoneVerification/VerifyPhoneNumber';
import UpdateGuestProfileMain from '@/screens/home/UpdateGuestProfile/UpdateGuestProfileMain';
import { useGetGuestUserQuery } from '@/store/services/userApi';
import AppConfig from '@config';
import { isTimestampEarlierThanNow } from '@libs';
import useLocalization from '@localizations';
import { useNavigation } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { useAppContext, useAppStore } from '@store';
import { IconButton, useTheme } from 'react-native-paper';
import { Amenities } from '../amenities/Amenities';
import { INavigationParamsProps, NotFound } from '../components';
import { GlobalClaimErrorDialog } from '../components/ClaimErrorDialog';
import { default as GuideCategory } from '../guides/Category';
import { default as GuidesContent } from '../guides/Content';
import { default as GuidesSection } from '../guides/Section';
import SectionsList from '../guides/SectionsList';
import { shouldNavigateToAuth } from '../login/LoginCognito/utils';
import HouseManual from '../manual/HouseManual';
import InstructionsDetail, { ManualDetail } from '../manual/ManualDetail';
import Messages from '../messages/Messages';
import SecurityDeposit from '../payment/SecurityDeposit';
import Place from '../places/Place';
import Places from '../places/Places';
import Profile from '../profile/Profile';
import { ClaimErrorProvider } from '../providers/ClaimErrorProvider';
import { CheckInRequested } from '../room-type/CheckInRequested';
import { QRCodeCheckIn } from '../room-type/QRCodeCheckIn';
import { SignatureTerms } from '../terms/SignatureTerms';
import Terms from '../terms/Terms';
import ReservationList from '../trips/ReservationList';
import Verification from '../verification/Verification';
import Confirmation from './Confirmation';
import Departure from './Departure';
import Home from './Home';
import { default as VerifyGuest } from './VerificationFlow';
import { GuestProfile } from './profile/GuestProfile';

const Stack = createStackNavigator();

const ProfileNavButton = () => {
  const navigation = useNavigation();
  const { company } = useAppStore();

  if (company?.isTestReservation) {
    return <></>;
  }

  return (
    <IconButton
      icon="account-circle"
      size={AppConfig.Styles.getAdjustedSize(30)}
      onPress={() => navigation.navigate('Profile')}
    />
  );
};

const HomeBackButton = () => {
  const navigation = useNavigation();
  const { company } = useAppStore();

  const { urlConfig, setUrlConfig } = useAppContext();

  const backButtonPress = () => {
    setUrlConfig({ ...urlConfig, id: '' });
    navigation.navigate('Reservations');
  };

  if (!shouldNavigateToAuth(company)) {
    return <> </>;
  }
  return (
    <IconButton
      icon="arrow-left"
      size={AppConfig.Styles.getAdjustedSize(30)}
      onPress={backButtonPress}
    />
  );
};

const renderProfileNavButton = () => {
  return <ProfileNavButton />;
};

const renderBackButton = () => {
  return <HomeBackButton />;
};

const ProfileBackButton = () => {
  const { company } = useAppStore();
  const navigation = useNavigation();
  const {
    urlConfig: { id },
  } = useAppContext();

  const backButtonPress = () => {
    navigation.navigate(
      id && !shouldNavigateToAuth(company) ? 'Home' : 'Reservations',
    );
  };

  return (
    <IconButton
      icon="arrow-left"
      size={AppConfig.Styles.getAdjustedSize(30)}
      onPress={backButtonPress}
    />
  );
};

const renderProfileBackButton = () => {
  return <ProfileBackButton />;
};

export const HomeNavigator = () => {
  const { company } = useAppStore();
  const { t } = useLocalization();
  const { reservation } = useAppStore();
  const isCheckedOut = isTimestampEarlierThanNow(
    reservation?.checkout,
    reservation?.property?.timezone,
  );
  const { colors } = useTheme();

  const { data: guestServiceUserData } = useGetGuestUserQuery();

  const getInitialRoute = () => {
    // Check if profile is created before
    const isProfileCreated = !!guestServiceUserData;

    if (isCheckedOut) {
      return 'Departure';
    } else if (company?.challengePassed || !company?.newLoginEnabled) {
      return 'Home';
    } else if (!isProfileCreated) {
      return 'CreateGuestProfile';
    } else {
      return 'Reservations';
    }
  };

  return (
    <ClaimErrorProvider>
      <Stack.Navigator
        initialRouteName={getInitialRoute()}
        screenOptions={() => ({
          animationEnabled: true,
          cardStyle: {
            backgroundColor: colors.background,
          },
          headerStyle: {
            borderBottomWidth: 0,
          },
          headerTitleStyle: {
            display: 'flex',
            justifyContent: 'center',
          },
        })}>
        {company?.newLoginEnabled &&
          !company.challengePassed && ( // TODO:REGISTRATION_RELEASE Remove on final release
            <Stack.Screen
              name="CreateGuestProfile"
              component={UpdateGuestProfileMain}
              options={{ headerShown: false }}
            />
          )}
        {company?.newLoginEnabled &&
          !company.challengePassed && ( // TODO:REGISTRATION_RELEASE Remove on final release
            <Stack.Screen
              name="AddPhoneNumber"
              component={AddPhoneNumber}
              options={{ headerShown: false }}
            />
          )}
        {company?.newLoginEnabled &&
          !company.challengePassed && ( // TODO:REGISTRATION_RELEASE Remove on final release
            <Stack.Screen
              name="VerifyPhoneNumber"
              component={VerifyPhoneNumber}
              options={{ headerShown: false }}
            />
          )}
        {company?.newLoginEnabled &&
          !company.challengePassed && ( // TODO:REGISTRATION_RELEASE Remove on final release
            <Stack.Screen
              name="Reservations"
              component={ReservationList}
              options={{
                title: t('no_upcoming_stays_trips_header'),
                headerLeft: renderBackButton,
                headerRight: renderProfileNavButton,
                headerLeftContainerStyle: {
                  display: 'none',
                },
              }}
            />
          )}
        <Stack.Screen
          name="Home"
          component={Home}
          options={() => ({
            title: t('booking_page_title'),
            headerLeft: renderBackButton,
            headerRight: renderProfileNavButton,
          })}
        />
        <Stack.Screen
          name="Profile"
          component={
            !company?.newLoginEnabled ||
            company.isTestReservation ||
            company.challengePassed
              ? Profile
              : GuestProfile
          }
          options={{
            headerLeft: renderProfileBackButton,
            headerRight: renderProfileNavButton,
            headerRightContainerStyle: {
              display: 'none',
            },
          }}
        />
        <Stack.Screen name="QRCodeCheckIn" component={QRCodeCheckIn} />
        <Stack.Screen name="CheckInRequested" component={CheckInRequested} />
        <Stack.Screen
          name="SectionsList"
          component={SectionsList}
          options={{
            title: t('page_title_sections_list'),
            headerRight: renderProfileNavButton,
          }}
        />
        <Stack.Screen
          name="Section"
          component={GuidesSection}
          options={{ title: t('guides'), headerRight: renderProfileNavButton }}
        />
        <Stack.Screen name="Category" component={GuideCategory} />
        <Stack.Screen name="Content" component={GuidesContent} />
        <Stack.Screen name="NotFound" component={NotFound} />
        <Stack.Screen
          name="Places"
          component={Places}
          options={() => ({
            title: t('booking_inbox_button_title'),
            headerRight: renderProfileNavButton,
          })}
        />
        <Stack.Screen
          name="Place"
          component={Place}
          options={() => ({
            title: t('booking_inbox_button_title'),
          })}
        />
        <Stack.Screen
          name="Messages"
          component={Messages}
          options={() => ({
            headerShown: false,
          })}
        />
        <Stack.Screen
          name="InstructionsDetail"
          component={InstructionsDetail}
        />
        <Stack.Screen
          name="Confirmation"
          component={Confirmation}
          options={({ route }) => ({
            headerShown: false,
            contentTitle: (route.params as INavigationParamsProps).contentTitle,
            contentDescription: (route.params as INavigationParamsProps)
              .contentDescription,
          })}
        />
        <Stack.Screen
          name="Departure"
          component={Departure}
          options={() => ({
            headerShown: false,
          })}
        />
        <Stack.Screen name="Amenities" component={Amenities} />
        <Stack.Screen
          name="SecurityDeposit"
          component={SecurityDeposit}
          options={{ headerShown: false }}
        />
        <Stack.Screen name="Verification" component={Verification} />

        {!company?.newLoginEnabled && ( //TODO:REGISTRATION_RELEASE Remove on final release
          <Stack.Screen name="Terms" component={Terms} />
        )}
        {company?.newLoginEnabled &&
          !company.challengePassed && ( //TODO:REGISTRATION_RELEASE Remove on final release
            <Stack.Screen name="SignatureTerms" component={SignatureTerms} />
          )}

        <Stack.Screen
          name="HouseManual"
          component={HouseManual}
          options={() => ({
            title: t('house_manual'),
            headerBackgroundImage: 'header_house_manual',
          })}
        />
        <Stack.Screen name="ManualDetail" component={ManualDetail} />
        <Stack.Screen
          name="VerifyGuest"
          component={VerifyGuest}
          options={{ title: t('guest_verification') }}
        />
      </Stack.Navigator>
      <GlobalClaimErrorDialog />
    </ClaimErrorProvider>
  );
};

export default HomeNavigator;
